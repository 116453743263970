/* Fonts and line heights */
/* 30px - using ems so the fields increase in line with user specified font-sizes */
/** white / black / grey colours */
/** colours */
/** link visited */
/** restful table */
/** font-awesome icons */
.aui-iconfont-edit:before {
  content: "\f044";
  /** fa-edit */
}
.aui-iconfont-view:before {
  content: "\f06e";
  /** fa-eye */
}
.aui-iconfont-success:before {
  content: "\f00c";
}
.aui-iconfont-help:before {
  content: "\f059";
  /** fa-question-circle */
}
.aui-iconfont-more:before {
  content: "\f141";
  /** fa-ellipsis-h */
}
.aui-iconfont-configure:before {
  content: "\f013";
  /** fa-cog */
}
.aui-iconfont-error:before {
  content: "\f06a";
  /** fa-exclamation-circle */
}
.aui-iconfont-appswitcher:before {
  content: "\f142";
  /** fa-ellipsis-v */
}
.aui-iconfont-expanded:before {
  content: "\f0d7";
  /** fa-caret-down */
}
.aui-iconfont-time:before {
  content: "\f017";
  /** fa-clock-o */
}
.aui-iconfont-warning:before {
  content: "\f071";
}

