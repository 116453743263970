html, body, p, div, h1, h2, h3, h4, h5, h6, ul, ol, dl, img, pre, form, fieldset {
  margin: 0;
  padding: 0;
}

img, fieldset {
  border: 0;
}

body, html {
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--ds-surface, #fff);
  color: var(--ds-text, #172b4d);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: var(--ds-font-size-100, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--ds-font-lineHeight-200, 1.42857);
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table {
  margin: var(--ds-space-150, 12px) 0 0 0;
}

a {
  color: var(--ds-link, #0052cc);
  text-decoration: none;
}

a:hover {
  color: var(--ds-link, #0065ff);
  text-decoration: underline;
}

a:active {
  color: var(--ds-link-pressed, #0747a6);
}

a:focus-visible {
  outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #2684ff);
  outline-offset: var(--ds-space-025, 2px);
}

@supports not selector(*:focus-visible) {
  a:focus {
    outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #4c9aff);
    outline-offset: var(--ds-space-025, 2px);
  }
}

h1 {
  font-size: 2.07143em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.10345;
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -.01em;
  margin-top: 40px;
}

h2 {
  font-size: 1.71429em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.16667;
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -.01em;
  margin-top: 40px;
}

h3 {
  font-size: 1.42857em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.2;
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -.008em;
  margin-top: 28px;
}

h4 {
  font-size: 1.14286em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.25;
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -.006em;
  margin-top: 24px;
}

h5 {
  font-size: 1em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.14286;
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -.003em;
  margin-top: 16px;
}

h6 {
  font-size: .857143em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.33333;
  font-weight: var(--ds-font-weight-semibold, 600);
  text-transform: uppercase;
  margin-top: 20px;
}

ul, ol, dl {
  padding-left: var(--ds-space-500, 40px);
}

dd, dd + dt, li + li, ul ul:not(:first-child), ol ul:not(:first-child), ul ol:not(:first-child), ol ol:not(:first-child) {
  margin-top: var(--ds-space-050, 4px);
}

p:first-child, ul:first-child, ol:first-child, dl:first-child, h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, blockquote:first-child, pre:first-child, form:first-child, table:first-child {
  margin-top: 0;
}

blockquote, q {
  color: inherit;
}

blockquote {
  padding-left: var(--ds-space-500, 40px);
  border: none;
}

[dir="rtl"] blockquote {
  padding-left: 0;
  padding-right: var(--ds-space-500, 40px);
}

blockquote:before, q:before {
  content: "“";
}

blockquote:after, q:after {
  content: "”";
}

blockquote:before {
  float: left;
  text-align: right;
  width: 1em;
  margin-left: -1em;
}

[dir="rtl"] blockquote:before {
  float: right;
  text-align: left;
  margin-right: -1em;
}

blockquote > :last-child {
  display: inline-block;
}

small {
  font-size: .785714em;
  font-style: inherit;
  color: var(--ds-text-subtlest, var(--ds-text-subtlest, #6b778c));
  line-height: 1.45455;
  font-weight: var(--ds-font-weight-bold, 700);
  margin-top: 16px;
  font-weight: normal;
}

code, kbd {
  font-family: SFMono-Medium, SF Mono, Segoe UI Mono, Roboto Mono, Ubuntu Mono, Menlo, Consolas, Courier, monospace;
}

var, address, dfn, cite {
  font-style: italic;
}

abbr {
  border-bottom: 1px var(--ds-border, #ccc) dotted;
  cursor: help;
}

@supports (color-scheme: dark) and (color-scheme: light) {
  [data-color-mode="light"] {
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
  }

  [data-color-mode="dark"] {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    color-scheme: dark;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead, tbody, tfoot {
  border-bottom: 2px solid var(--ds-border, #dfe1e6);
}

td, th {
  padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
  text-align: left;
  border: none;
}

th {
  vertical-align: top;
}

td:first-child, th:first-child {
  padding-left: 0;
}

td:last-child, th:last-child {
  padding-right: 0;
}

caption {
  font-size: 1.42857em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.2;
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -.008em;
  margin-top: 28px;
  margin-bottom: var(--ds-space-100, 8px);
  text-align: left;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

@-moz-document url-prefix() {
  img {
    font-size: 0;
  }

  img:-moz-broken {
    font-size: inherit;
  }
}

button {
  font-family: inherit;
}

.assistive {
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.aui-icon {
  text-align: left;
  text-indent: -999em;
  vertical-align: text-bottom;
  background-position: 0 0;
  background-repeat: no-repeat;
  border: none;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.aui-icon-small, .aui-icon-large {
  vertical-align: text-top;
  line-height: 0;
  position: relative;
}

.aui-icon-small {
  width: 16px;
  height: 16px;
}

.aui-icon-large {
  width: 32px;
  height: 32px;
}

.aui-icon-small:before, .aui-icon-large:before {
  color: inherit;
  -webkit-font-smoothing: antialiased;
  text-indent: 0;
  speak: none;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 0;
}

.aui-icon-small:before {
  margin-top: -8px;
  font-size: 16px;
}

.aui-icon-large:before {
  margin-top: -16px;
  font-size: 32px;
}

.icon-move, .icon-move-d, .icon-dropdown, .icon-dropdown-d, .icon-dropdown-active, .icon-dropdown-active-d, .icon-minimize, .icon-minimize-d, .icon-maximize, .icon-maximize-d {
  background-repeat: no-repeat;
}

.aui-icon.icon-move, .aui-icon.icon-move-d, .aui-icon.icon-dropdown, .aui-icon.icon-dropdown-d, .aui-icon.icon-dropdown-active, .aui-icon.icon-dropdown-active-d, .aui-icon.icon-minimize, .aui-icon.icon-minimize-d, .aui-icon.icon-maximize, .aui-icon.icon-maximize-d {
  vertical-align: baseline;
  width: 13px;
  height: 13px;
  background-position: 0 0 !important;
}

.icon-move {
  cursor: move;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANAQAAAABakNnRAAAAAnRSTlMAAHaTzTgAAAAlSURBVHgBY2BiYGBnYOBvADGUFBiSDBj+/wCSIDYTSBwoC2QAAF4LBNQUu22rAAAAAElFTkSuQmCC");
}

.icon-move-d {
  cursor: move;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAAAAABXgLugAAAAAnRSTlMAAHaTzTgAAAAtSURBVHgBY4CAXDAJ4+TmInOgXAyVuXAMIuC8XCBGBqhyMBaaSTjtg3DQVQIAa64U3lyaxpQAAAAASUVORK5CYII=");
}

.icon-dropdown {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAQAAADY4iz3AAAAKUlEQVQY02NgwAf+YwFQCezKKZRCtwdFF4oD0A1ESFDBGVil8IQGbgAAiaBjnU8tP0kAAAAASUVORK5CYII=");
}

.icon-dropdown-d {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAQAAADY4iz3AAAAKklEQVQY02NgwAdy/2NCqAR25RRKoduDogvFAegGIiSo4AysUnhCAzcAAJOYRxkKj+5hAAAAAElFTkSuQmCC");
}

.icon-dropdown-active {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAQAAADY4iz3AAAAKElEQVQY02NgwAf+YwE4JaCSlEhh2o2kC91RKAYyoPOo7kI8oYEbAACHhecZSiXhsAAAAABJRU5ErkJggg==");
}

.icon-dropdown-active-d {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAQAAADY4iz3AAAAKElEQVQY02NgwAdy/2NCnBJQSUqkMO1G0oXuKBQDGdB5VHchntDADQAE0qTxqu2HqgAAAABJRU5ErkJggg==");
}

.icon-minimize {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAQAAADY4iz3AAAAHklEQVQY02NgwAf+YwE4JaCS5Epht3/wSOEJDdwAAFcvh3lwBRT4AAAAAElFTkSuQmCC");
}

.icon-minimize-d {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAQAAADY4iz3AAAAH0lEQVQY02NgwAdy/2NCnBJQSXKlsNs/eKTwhAZuAAAbK2Cx/Io00wAAAABJRU5ErkJggg==");
}

.icon-maximize {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAQAAADY4iz3AAAAJUlEQVQY02NgIAT+YwDipNBNwS+F3SjCUtjdSoYDiJDC6VncAAAKY3uF7U/mdQAAAABJRU5ErkJggg==");
}

.icon-maximize-d {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAQAAADY4iz3AAAAJklEQVQY02NgIARy/6ND4qTQTcEvhd0owlLY3UqGA4iQwulZ3AAAn8BYKXiL6OYAAAAASUVORK5CYII=");
}

form.aui .icon-date, .aui-icon-date {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAARElEQVR4AWMoIBGANFgXr8dE927fw0TkaCDLSf/B4O7duwQZ5Gq4iwNccDGHI4gIARuA6tAYJGig1En0DyVMQFcnkQgA7103vYurRvEAAAAASUVORK5CYII=");
}

form.aui .icon-range, .aui-icon-range {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAARklEQVR4AWMoIBGANFgXr8dEd7EBcjSQ5aT/YAA0gCCDXA0Q911wMSeIUGwA8gkyyNWAsJRyJ6F5mmQNhJ2EFtN0ijgSAQD0WQfB7IGD0QAAAABJRU5ErkJggg==");
}

form.aui .icon-required, .aui-icon-required {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAARUlEQVR4AWOAgQsu5v8ZSAIIjaVAbA4yAEqXkmpAMxDfABkApZtJNYARiHtABkBpRlIN0AbiK0AcBMQrQHyGkQdGwSgAAPtCJnBUV/TSAAAAAElFTkSuQmCC");
}

form.aui .icon-users, .aui-icon-users {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArElEQVR4AWOgCbAuXi8HxLuA+DOUliPVgN1A/B8J78epuKCgwBOIn0GxJ9SAz2gG/MSllgHK+Q/Fz/C5AFMtpuBzWBhADfkCpeWxqoUKeoE4QPwExMbhU9LUAm2UAuK9IL8D8R4glmQgYLIGCCMZsBYtDNZiqkUIlCH5qxxqwCc0Az5jVQsVfI4k+A5qwH90jEstA4SDwLgNwFQ7jAy4gSR4F2rAaTQDjuFSCwBFeGK4drVwMAAAAABJRU5ErkJggg==");
}

form.aui .icon-help, .aui-icon-help, form.aui .icon-inline-help, .aui-icon-inline-help {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAABG0lEQVR4AU2RNVYtQRiE+7m7IxHRlRxnHWwAty9lDbg7K4AUh7kJ7vDwEGLcvU6f6cP8NVJVXe3GFWGqWedSWBcLm2DxjmZuuSFGgxATu9X/tWt+zTAPdBNnnBNHl5whP0KDRJm1ezjimn6SpArlNhqRCLd02eA0h+SSzS47PJfuUEvEUMMN/yR/8UC5DVaKJej/T6PVGNbxjC1SeKtvBgfMawQVHhuGS9oC+8ninGW3XNq4MlwRDAyzx1enFDgxbOAFAhP0BpTHf7dIZyW64f1F1hqi3NLhW584Ej77StskKkIjD5Ra6y0bwlvLC7inyR31iCJd2MF5Zs+0U84wr58uq5U7bvBoEDxupBrVHCwi1LLJNaf8F4s4/xGiuqDPs2tUOAAAAABJRU5ErkJggg==");
}

.aui-message .icon-close, .aui-icon-close {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAOUlEQVR4AWOgGogCQjQ+KrcfCKMw+MgCECEMHqYSdGlMJZjSCCWo0oQVELaCsCMJe5NwQBEOasoBAGP2Kx0Yi3q/AAAAAElFTkSuQmCC");
}

.aui-message .icon-close-inverted, .aui-message.error .icon-close, .aui-icon-close-inverted {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAOklEQVR4AWOgFvi/4P8CdD4qFwQWYPCRBSBCGDxMJejSmEowpRFKUKUJKyBsBWFHEvYm4YAiHNSUAwDHH6hxrraMqwAAAABJRU5ErkJggg==");
}

.aui-icon-dropdown {
  content: "";
  text-align: left;
  text-indent: -999em;
  vertical-align: baseline;
  border: 4px solid #0000;
  border-top-color: #333;
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.aui-button[aria-disabled="true"] .aui-icon, .aui-button[disabled] .aui-icon {
  color: #70707080;
}

.aui-iconfont-edit:before {
  content: "";
}

.aui-iconfont-view:before {
  content: "";
}

.aui-iconfont-success:before {
  content: "";
}

.aui-iconfont-help:before {
  content: "";
}

.aui-iconfont-more:before {
  content: "";
}

.aui-iconfont-configure:before {
  content: "";
}

.aui-iconfont-error:before {
  content: "";
}

.aui-iconfont-appswitcher:before {
  content: "";
}

.aui-iconfont-expanded:before {
  content: "";
}

.aui-iconfont-time:before {
  content: "";
}

.aui-iconfont-warning:before {
  content: "";
}

.aui-icon-wait {
  background-image: url("data:image/gif;base64,R0lGODlhEAAQAPMPALu7u5mZmTMzM93d3REREQAAAHd3d1VVVWZmZqqqqoiIiO7u7kRERCIiIgARAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFBwAPACwAAAAAEAAQAEAEcPDJtyg6dUrFetDTIopMoSyFcxxD1krD8AwCkASDIlPaUDQLR6G1Cy0SgqIkE1IQGMrFAKCcGWSBzwPAnAwarcKQ15MpTMJYd1ZyUDXSDGelBY0qIoBh/ZoYGgELCjoxCRRvIQcGD1kzgSAgAACQDxEAIfkEBQcADwAsAAAAAA8AEAAABF3wyfkMkotOJpscRKJJwtI4Q1MAoxQ0RFBw0xEvhGAVRZZJh4JgMAEQW7TWI4EwGFjKR+CAQECjn8DoN0kwDtvBT8FILAKJgfoo1iAGAPNVY9DGJXNMIHN/HJVqIxEAIfkEBQcADwAsAAAAABAADwAABFrwyfmColgiydpaQiY5x9Ith7hURdIl0wBIhpCAjKIIxaAUPQ0hFQsAC7MJALFSFi4SgC4wyHyuCYNWxH3AuhSEotkNGAALAPqqkigG8MWAjAnM4A8594vPUyIAIfkEBQcADwAsAAAAABAAEAAABF3wySkDvdKsddg+APYIWrcg2DIRQAcU6DJICjIsjBEETLEEBYLqYSDdJoCGiHgZwG4LQCCRECEIBAdoF5hdEIWwgBJqDs7DgcKyRHZl3uUwuhm2AbNNW+LV7yd+FxEAIfkEBQcACAAsAAAAABAADgAABEYQyYmMoVgeWQrP3NYhBCgZBdAFRUkdBIAUguVVo1ZsWFcEGB5GMBkEjiCBL2a5ZAi+m2SAURExwKqPiuCafBkvBSCcmiYRACH5BAUHAA4ALAAAAAAQABAAAARs0MnpAKDYrbSWMp0xZIvBKYrXjNmADOhAKBiQDF5gGcICNAyJTwFYTBaDQ0HAkgwSmAUj0OkMrkZM4HBgKK7YTKDRICAo2clAEIheKc9CISjEVTuEQrJASGcSBQcSUFEUDQUXJBgDBW0Zj34RACH5BAUHAA8ALAAAAAAQABAAAARf8Mn5xqBYgrVC4EEmBcOSfAEjSopJMglmcQlgBYjE5NJgZwjCAbO4YBAJjpIjSiAQh5ayyRAIDKvJIbnIagoFRFdkQDQKC0RBsCIUFAWsT7RwG410R8HiiK0WBwJjFBEAIfkEBQcADgAsAQABAA8ADwAABFrQybEWADXJLUHHAMJxIDAgnrOo2+AOibEMh1LN62gIxphzitRoCDAYNcNN6FBLShao4WzwHDQKvVGhoFAwGgtFgQHENhoB7nCwHRAIC0EyUcC8Zw1ha3NIRgAAIfkEBQcADwAsAAAAABAAEAAABGDwyfnWoljaNYYFV+Zx3hCEGEcuypBtMJBISpClAWLfWODymIFiCJwMDMiZBNAAYFqUAaNQ2E0YBIXGURAMCo1AAsFYBBoIScBJEwgSVcmP0li4FwcHz+FpCCQMPCFINxEAIfkEBQcADgAsAAABABAADwAABFzQyemWXYNqaSXY2vVtw3UNmROM4JQowKKlFOsgRI6ASQ8IhSADFAjAMIMAgSYJtByxyQIhcEoaBcSiwegpDgvAwSBJ0AIHBoCQqIAEi/TCIAABGhLG8MbcKBQgEQAh+QQFBwAPACwAAAEAEAAPAAAEXfDJSd+qeK5RB8fDRRWFspyotAAfQBbfNLCVUSSdKDV89gDAwcFBIBgywMRnkWBgcJUDKSZRIKAPQcGwYByAAYTEEJAAJIGbATEQ+B4ExmK9CDhBd8ThdHw/AmUYEQAh+QQFBwAPACwAAAEADwAPAAAEXvBJQIa8+ILSspdHkXxS9wxF4Q3L2aTBeC0sFjhAtuyLIjAMhYc2GBgaSKGuyNoBDp7czFAgeBIKwC6kWCAMxUSAFjtNCAAFGGF5tCQLAaJnWCTqHoREvQuQJAkyGBEAOw==");
}

.aui-dropdown2 .active .aui-icon-wait, .aui-button-primary .aui-icon-wait, .aui-button-primary[aria-disabled="true"] .aui-icon-wait {
  background-image: url("data:image/gif;base64,R0lGODlhEAAQAPMPAKC72NTg7e7z97rN4oaozWyWwlKDuP///5Sy08jX6OLq8q7F3XmfyEV6s/r7/AAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUAI/eAAh+QQFBwAPACwAAAAAEAAQAEAEcPDJ1+gadUqUOtFTI4rJgTSHMwxG1kqG8RhBwRAGIlOacSgNx6G1CzUYgaIkE0IIEsqGoaCcAWSEz6PAnBgUrcOQ15MhTMJYd1ZyUDXSDGelBY0qogJg/ZoAFAQNCDoxDBRvIQMAD1kzgSAgBQWQDxEAIfkEBQcADwAsAAAAAA8AEAAABF3wyfnAko3OJJU0AqNJQaM4hnIUo0QoAnFw0xA3QmAdR5ZJg0PAMCkQW7TWg7EAAFjKB2GwWECjH8LoN2EkBlvDD5FgNAgMg/oo1iwMBfNVA9DGJXMMIXN/HJVqIxEAIfkEBQcADwAsAAAAABAADwAABFrwyfkIolgumVRrQSY5Q9M1g9hUB9Mxk1FIQMCACYIEh4EcPY0gFSMAD7NJYbFSHi6Sgo5gyHyuDIBWxH3AupQFotklAAqNAvqqkiAM8IaBjCHM4A8594vPUyIAIfkEBQcADwAsAAAAABAAEAAABFzwySkJvRK0N5IcBfYEAOct2CYJRReijSEhi5wABOE1lqACpIfqUVCIHiWKrNbYEBgM0UIgcIByBNllcegGKKGLYWxQGRCW46SQfYzVQkmj0BweC7Im3C23w/UYEQAh+QQFBwAIACwAAAAAEAAOAAAERRDJiQCgWA55Ds/c1iECKAFeR5DYIBTIEVheFU/DsWH1Su2YwMSAIPiCnUPJcsmUPkNMQZY5TqYdIkJrSma4lAJQAgZFAAAh+QQFBwAOACwAAAAAEAAQAAAEa9DJ6Uqh2DUmEZCAkTUAhyAOooiZsRinIaBUwYgIYQFBpSSSRgFRaEwahoEjwJIYOJRGgmDUGK5VCmEwSCCu2AxBoRAsohhDgIBBNh1PCTBoMDaqgkPSIchmGAdKDlBoEwoHFyNpB2wZjn4RACH5BAUHAA8ALAAAAAAQABAAAARb8Mn5jKFYFkkIz9zFeEQCSkgzPgmDWc3DFBaxSMktGXS2CANM44JZuCaNJIixWASTygwjEQgATpMBI4rdPBYxEGCheDRugZPggHhceaDGmkiMOw4XxzE+CIQpEQAh+QQFBwAOACwBAAEADwAPAAAEVtBJI0uRTOpab1nb5l3L5TSnZlAOAzjGgEiNgYZA8G73hpihYAFAzAQ1n4HsSCsFa6yawrHbHA4IRGI6S/gOAIWC4JiFDFeKoBE4Mg4XwdqhAKo0g2MEACH5BAUHAA8ALAAAAAAQABAAAARd8Mn5WqNY3meMLBtmXB1HhFTjdQ3iYZtlFYyEEBlMLHiOES8fxYAoCicGgLL2URQwrmHicOhNEgKExnEIeBQExiLxICgWEsLTFgjUesGJotF+DBw+xzcgIec2SjkRACH5BAUHAA4ALAAAAQAQAA8AAARa0MnpWpOGamll2Vr3bZlmlMQITgxSXFRJqc4i3AvI7EVw/AkQgeBqnAgCmYSwcrwmjUWAKVHUGooEw4EYOAcACUNGGCQKgi1ME3CYHYD2pmB1JII15QaBAEUAACH5BAUHAA8ALAAAAQAQAA8AAARY8MlJX6t4NlMNx8ZFHQdSiU3xFeTxTYZaAQfTiZKiZ0/hGwOHQADI+BifBiOBsVUGkpcEsXA+Ao9iYuAjLCQAgqQgETMACwOWJ2CiHwQmSL6ANoq8shgTAQAh+QQFBwAPACwAAAEADwAPAAAEWvDJIoy8+JajspfDwXxS9xjH4RnNaRLj1bAY4RRZKyFBkiCemcEAUBiBuaGlVRh4cDLAQ+BhIKBO4AIwZBBOsNNEUEBIF5aHTh1YPMwPBmB9cb+ljy8pHsNEAAA7");
}

.aui-icon-success, .aui-icon-warning, .aui-icon-info, .aui-icon-error, .aui-icon-hint, .aui-icon-generic {
  width: 16px;
  height: 16px;
  line-height: 0;
  position: relative;
}

.aui-icon-success:before, .aui-icon-warning:before, .aui-icon-info:before, .aui-icon-error:before, .aui-icon-hint:before, .aui-icon-generic:before {
  color: #707070;
  content: "";
  -webkit-font-smoothing: antialiased;
  speak: none;
  text-indent: 0;
  margin-top: -8px;
  font-family: Atlassian Icons;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 0;
}

.aui-icon-generic:before {
  color: #707070;
  content: "";
}

.aui-icon-error:before {
  color: #d04437;
  content: "";
}

.aui-icon-hint:before, .aui-icon-info:before {
  color: #707070;
  content: "";
}

.aui-icon-success:before {
  color: #14892c;
  content: "";
}

.aui-icon-warning:before {
  color: #f6c342;
  content: "";
}

@font-face {
  font-family: Atlassian Icons;
  src: url("atlassian-icons.1b8d29e0.eot");
  src: url("atlassian-icons.1b8d29e0.eot#iefix") format("embedded-opentype"), url("atlassian-icons.3ff91726.woff") format("woff"), url("atlassian-icons.55c3dedb.ttf") format("truetype"), url("atlassian-icons.688af381.svg#atlassian-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.aui-iconfont-add:before {
  content: "";
}

.aui-iconfont-add-comment:before {
  content: "";
}

.aui-iconfont-add-small:before {
  content: "";
}

.aui-iconfont-approve:before {
  content: "";
}

.aui-iconfont-appswitcher:before {
  content: "";
}

.aui-iconfont-arrows-down:before {
  content: "";
}

.aui-iconfont-arrows-left:before {
  content: "";
}

.aui-iconfont-arrows-right:before {
  content: "";
}

.aui-iconfont-arrows-up:before {
  content: "";
}

.aui-iconfont-attachment:before {
  content: "";
}

.aui-iconfont-attachment-small:before {
  content: "";
}

.aui-iconfont-autocomplete-date:before {
  content: "";
}

.aui-iconfont-back-page:before {
  content: "";
}

.aui-iconfont-blogroll:before {
  content: "";
}

.aui-iconfont-bp-decisions:before {
  content: "";
}

.aui-iconfont-bp-default:before {
  content: "";
}

.aui-iconfont-bp-files:before {
  content: "";
}

.aui-iconfont-bp-howto:before {
  content: "";
}

.aui-iconfont-bp-jira:before {
  content: "";
}

.aui-iconfont-bp-meeting:before {
  content: "";
}

.aui-iconfont-bp-retrospective:before {
  content: "";
}

.aui-iconfont-bp-sharedlinks:before {
  content: "";
}

.aui-iconfont-bp-troubleshooting:before {
  content: "";
}

.aui-iconfont-build:before {
  content: "";
}

.aui-iconfont-calendar:before {
  content: "";
}

.aui-iconfont-close-dialog:before {
  content: "";
}

.aui-iconfont-collapsed:before {
  content: "";
}

.aui-iconfont-comment:before {
  content: "";
}

.aui-iconfont-configure:before {
  content: "";
}

.aui-iconfont-confluence:before {
  content: "";
}

.aui-iconfont-copy-clipboard:before {
  content: "";
}

.aui-iconfont-custom-bullet:before {
  content: "";
}

.aui-iconfont-delete:before {
  content: "";
}

.aui-iconfont-deploy:before {
  content: "";
}

.aui-iconfont-details:before {
  content: "";
}

.aui-iconfont-devtools-arrow-left:before {
  content: "";
}

.aui-iconfont-devtools-arrow-right:before {
  content: "";
}

.aui-iconfont-devtools-branch:before {
  content: "";
}

.aui-iconfont-devtools-branch-small:before {
  content: "";
}

.aui-iconfont-devtools-browse-up:before {
  content: "";
}

.aui-iconfont-devtools-checkout:before {
  content: "";
}

.aui-iconfont-devtools-clone:before {
  content: "";
}

.aui-iconfont-devtools-commit:before {
  content: "";
}

.aui-iconfont-devtools-compare:before {
  content: "";
}

.aui-iconfont-devtools-file:before {
  content: "";
}

.aui-iconfont-devtools-file-binary:before {
  content: "";
}

.aui-iconfont-devtools-file-commented:before {
  content: "";
}

.aui-iconfont-devtools-folder-closed:before {
  content: "";
}

.aui-iconfont-devtools-folder-open:before {
  content: "";
}

.aui-iconfont-devtools-fork:before {
  content: "";
}

.aui-iconfont-devtools-pull-request:before {
  content: "";
}

.aui-iconfont-devtools-repository:before {
  content: "";
}

.aui-iconfont-devtools-repository-forked:before {
  content: "";
}

.aui-iconfont-devtools-repository-locked:before {
  content: "";
}

.aui-iconfont-devtools-side-diff:before {
  content: "";
}

.aui-iconfont-devtools-submodule:before {
  content: "";
}

.aui-iconfont-devtools-tag:before {
  content: "";
}

.aui-iconfont-devtools-tag-small:before {
  content: "";
}

.aui-iconfont-devtools-task-cancelled:before {
  content: "";
}

.aui-iconfont-devtools-task-disabled:before {
  content: "";
}

.aui-iconfont-devtools-task-in-progress:before {
  content: "";
}

.aui-iconfont-doc:before {
  content: "";
}

.aui-iconfont-down:before {
  content: "";
}

.aui-iconfont-drag-vertical:before {
  content: "";
}

.aui-iconfont-edit:before {
  content: "";
}

.aui-iconfont-edit-small:before {
  content: "";
}

.aui-iconfont-editor-align-center:before {
  content: "";
}

.aui-iconfont-editor-align-left:before {
  content: "";
}

.aui-iconfont-editor-align-right:before {
  content: "";
}

.aui-iconfont-editor-bold:before {
  content: "";
}

.aui-iconfont-editor-color:before {
  content: "";
}

.aui-iconfont-editor-emoticon:before {
  content: "";
}

.aui-iconfont-editor-help:before {
  content: "";
}

.aui-iconfont-editor-hr:before {
  content: "";
}

.aui-iconfont-editor-indent:before {
  content: "";
}

.aui-iconfont-editor-italic:before {
  content: "";
}

.aui-iconfont-editor-layout:before {
  content: "";
}

.aui-iconfont-editor-list-bullet:before {
  content: "";
}

.aui-iconfont-editor-list-number:before {
  content: "";
}

.aui-iconfont-editor-macro-toc:before {
  content: "";
}

.aui-iconfont-editor-mention:before {
  content: "";
}

.aui-iconfont-editor-outdent:before {
  content: "";
}

.aui-iconfont-editor-styles:before {
  content: "";
}

.aui-iconfont-editor-symbol:before {
  content: "";
}

.aui-iconfont-editor-table:before {
  content: "";
}

.aui-iconfont-editor-task:before {
  content: "";
}

.aui-iconfont-editor-underline:before {
  content: "";
}

.aui-iconfont-email:before {
  content: "";
}

.aui-iconfont-error:before {
  content: "";
}

.aui-iconfont-expanded:before {
  content: "";
}

.aui-iconfont-file-code:before {
  content: "";
}

.aui-iconfont-file-doc:before {
  content: "";
}

.aui-iconfont-file-java:before {
  content: "";
}

.aui-iconfont-file-pdf:before {
  content: "";
}

.aui-iconfont-file-ppt:before {
  content: "";
}

.aui-iconfont-file-txt:before {
  content: "";
}

.aui-iconfont-file-wav:before {
  content: "";
}

.aui-iconfont-file-xls:before {
  content: "";
}

.aui-iconfont-file-zip:before {
  content: "";
}

.aui-iconfont-flag:before {
  content: "";
}

.aui-iconfont-focus:before {
  content: "";
}

.aui-iconfont-group:before {
  content: "";
}

.aui-iconfont-handle-horizontal:before {
  content: "";
}

.aui-iconfont-help:before {
  content: "";
}

.aui-iconfont-hipchat:before {
  content: "";
}

.aui-iconfont-homepage:before {
  content: "";
}

.aui-iconfont-image:before {
  content: "";
}

.aui-iconfont-image-extrasmall:before {
  content: "";
}

.aui-iconfont-image-small:before {
  content: "";
}

.aui-iconfont-info:before {
  content: "";
}

.aui-iconfont-jira:before {
  content: "";
}

.aui-iconfont-jira-completed-task:before {
  content: "";
}

.aui-iconfont-jira-test-session:before {
  content: "";
}

.aui-iconfont-like:before {
  content: "";
}

.aui-iconfont-like-small:before {
  content: "";
}

.aui-iconfont-link:before {
  content: "";
}

.aui-iconfont-list-add:before {
  content: "";
}

.aui-iconfont-list-remove:before {
  content: "";
}

.aui-iconfont-locked:before {
  content: "";
}

.aui-iconfont-locked-small:before {
  content: "";
}

.aui-iconfont-macro-code:before {
  content: "";
}

.aui-iconfont-macro-default:before {
  content: "";
}

.aui-iconfont-macro-gallery:before {
  content: "";
}

.aui-iconfont-macro-status:before {
  content: "";
}

.aui-iconfont-more:before {
  content: "";
}

.aui-iconfont-nav-children:before {
  content: "";
}

.aui-iconfont-page-blank:before {
  content: "";
}

.aui-iconfont-page-blogpost:before {
  content: "";
}

.aui-iconfont-page-default:before {
  content: "";
}

.aui-iconfont-page-template:before {
  content: "";
}

.aui-iconfont-pages:before {
  content: "";
}

.aui-iconfont-quote:before {
  content: "";
}

.aui-iconfont-redo:before {
  content: "";
}

.aui-iconfont-remove:before {
  content: "";
}

.aui-iconfont-remove-label:before {
  content: "";
}

.aui-iconfont-review:before {
  content: "";
}

.aui-iconfont-rss:before {
  content: "";
}

.aui-iconfont-search:before {
  content: "";
}

.aui-iconfont-search-small:before {
  content: "";
}

.aui-iconfont-share:before {
  content: "";
}

.aui-iconfont-sidebar-link:before {
  content: "";
}

.aui-iconfont-sourcetree:before {
  content: "";
}

.aui-iconfont-space-default:before {
  content: "";
}

.aui-iconfont-space-personal:before {
  content: "";
}

.aui-iconfont-star:before {
  content: "";
}

.aui-iconfont-success:before {
  content: "";
}

.aui-iconfont-table-bg:before {
  content: "";
}

.aui-iconfont-table-col-left:before {
  content: "";
}

.aui-iconfont-table-col-remove:before {
  content: "";
}

.aui-iconfont-table-col-right:before {
  content: "";
}

.aui-iconfont-table-copy-row:before {
  content: "";
}

.aui-iconfont-table-cut-row:before {
  content: "";
}

.aui-iconfont-table-header-column:before {
  content: "";
}

.aui-iconfont-table-header-row:before {
  content: "";
}

.aui-iconfont-table-merge:before {
  content: "";
}

.aui-iconfont-table-no-bg:before {
  content: "";
}

.aui-iconfont-table-paste-row:before {
  content: "";
}

.aui-iconfont-table-remove:before {
  content: "";
}

.aui-iconfont-table-row-down:before {
  content: "";
}

.aui-iconfont-table-row-remove:before {
  content: "";
}

.aui-iconfont-table-row-up:before {
  content: "";
}

.aui-iconfont-table-split:before {
  content: "";
}

.aui-iconfont-teamcals:before {
  content: "";
}

.aui-iconfont-time:before {
  content: "";
}

.aui-iconfont-undo:before {
  content: "";
}

.aui-iconfont-unfocus:before {
  content: "";
}

.aui-iconfont-unlocked:before {
  content: "";
}

.aui-iconfont-unstar:before {
  content: "";
}

.aui-iconfont-unwatch:before {
  content: "";
}

.aui-iconfont-up:before {
  content: "";
}

.aui-iconfont-user:before {
  content: "";
}

.aui-iconfont-user-status:before {
  content: "";
}

.aui-iconfont-view:before {
  content: "";
}

.aui-iconfont-view-card:before {
  content: "";
}

.aui-iconfont-view-list:before {
  content: "";
}

.aui-iconfont-view-table:before {
  content: "";
}

.aui-iconfont-warning:before {
  content: "";
}

.aui-iconfont-watch:before {
  content: "";
}

.aui-iconfont-workbox:before {
  content: "";
}

.aui-iconfont-workbox-empty:before {
  content: "";
}

.aui-iconfont-blogroll-large:before {
  content: "";
}

.aui-iconfont-email-large:before {
  content: "";
}

.aui-iconfont-layout-1col-large:before {
  content: "";
}

.aui-iconfont-layout-2col-large:before {
  content: "";
}

.aui-iconfont-layout-2col-left-large:before {
  content: "";
}

.aui-iconfont-layout-2col-right-large:before {
  content: "";
}

.aui-iconfont-layout-3col-center-large:before {
  content: "";
}

.aui-iconfont-layout-3col-large:before {
  content: "";
}

.aui-iconfont-nav-children-large:before {
  content: "";
}

.aui-iconfont-pages-large:before {
  content: "";
}

.aui-iconfont-sidebar-link-large:before {
  content: "";
}

.aui-iconfont-teamcals-large:before {
  content: "";
}

.aui-iconfont-user-large:before {
  content: "";
}

.aui-iconfont-bp-requirements:before {
  content: "";
}

.aui-iconfont-weblink:before {
  content: "";
}

.aui-iconfont-configure-columns:before {
  content: "";
}

.aui-iconfont-export:before {
  content: "";
}

.aui-iconfont-export-list:before {
  content: "";
}

.aui-iconfont-file-image:before {
  content: "";
}

.aui-iconfont-admin-fusion:before {
  content: "";
}

.aui-iconfont-admin-jira-fields:before {
  content: "";
}

.aui-iconfont-admin-issue:before {
  content: "";
}

.aui-iconfont-admin-notifications:before {
  content: "";
}

.aui-iconfont-admin-roles:before {
  content: "";
}

.aui-iconfont-admin-jira-screens:before {
  content: "";
}

.aui-iconfont-pause:before {
  content: "";
}

.aui-iconfont-priority-highest:before {
  content: "";
}

.aui-iconfont-priority-high:before {
  content: "";
}

.aui-iconfont-priority-medium:before {
  content: "";
}

.aui-iconfont-priority-low:before {
  content: "";
}

.aui-iconfont-priority-lowest:before {
  content: "";
}

.aui-iconfont-refresh-small:before {
  content: "";
}

.aui-iconfont-share-list:before {
  content: "";
}

.aui-iconfont-switch-small:before {
  content: "";
}

.aui-iconfont-version:before {
  content: "";
}

.aui-iconfont-workflow:before {
  content: "";
}

.aui-iconfont-admin-jira-settings:before {
  content: "";
}

.aui-iconfont-component:before {
  content: "";
}

.aui-iconfont-reopen:before {
  content: "";
}

.aui-iconfont-roadmap:before {
  content: "";
}

.aui-iconfont-deploy-success:before {
  content: "";
}

.aui-iconfont-deploy-fail:before {
  content: "";
}

.aui-iconfont-file-generic:before {
  content: "";
}

.aui-iconfont-arrow-down:before {
  content: "";
}

.aui-iconfont-arrow-up:before {
  content: "";
}

.aui-iconfont-file-video:before {
  content: "";
}

.aui-icon-small:before, .aui-icon-large:before {
  src: url("atlassian-icons.1b8d29e0.eot");
  src: url("atlassian-icons.1b8d29e0.eot#iefix") format("embedded-opentype"), url("atlassian-icons.3ff91726.woff") format("woff"), url("atlassian-icons.55c3dedb.ttf") format("truetype"), url("atlassian-icons.688af381.svg#atlassian-icons") format("svg");
  font-family: Atlassian Icons;
  font-style: normal;
  font-weight: normal;
}



/*# sourceMappingURL=vendor.css.map */
